@charset "utf-8";

// ------------ COMMON HEADER ------------

header {
	width: 100%;

	div.inner {
		max-width: 1306px;
		height: 83px;
		margin: auto;
		@extend .wrapper;
		position: relative;
		box-sizing: border-box;
		padding: 0;

		@include mq(pc) {
			min-width: inherit;
			height: 57px;
		}
	}

	// Logo
	h1,
	div.header_logo {
		@extend .vertical;

		a {
			display: block;

			img {
				width: 324px;
				height: auto;

				@include mq(pc) {
					width: 224px;
				}
			}
		}
	}

	// CV
	.cv {
		display: inline-block;
		position: absolute;
		top: 50%;
		right: 16px;
		transform: translateY(-50%);

		@include mq(pc) {
			display: none;
		}

		&:hover {
			opacity: 0.8;
		}

		img {
			width: 217px;
		}
	}
}

// ------------　スクロール後FIXED設定 ------------

header.fixed {
	background: rgba(255, 255, 255, .7);
	position: fixed;
	z-index: 100;
}