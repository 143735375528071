@charset "utf-8";

// ------------ FORM ------------

// フォーム
div.form {

	.sec {
		@include mq(sp) {
			padding-top: 30px;
		}
	}

	// 背景
	width: 100%;
	height: auto;
	background-image: url(../img/common/bg_body.png);
	background-size: 600px;
	background-repeat: repeat;
	background-position: center;

	// ポップ
	span.essential {
		white-space: nowrap;
		padding: 3px 10px;
		font-size: 80%;
		color: #FFF;
		background-color: $main-color;
		font-weight: normal;
		margin: 0 5px;
	}

	div.eqn5_form {
		max-width: 1000px;
		margin: 0 auto;

		// table
		table {
			@extend .tb_block;

			table {
				width: 100%;
			}

			th,
			td {
				text-align: left;
			}

			th {
				width: 25%;
				background-color: $box;
				border-top: 1px solid $border-color;
				font-weight: bold;
				position: relative;
				vertical-align: top;

				@include mq_up() {
					padding: 30px 20px;
				}

				@include mq() {
					width: 100%;
					padding: 10px;
					border: none;
				}

				p {
					display: inline-block;
					vertical-align: middle;
					word-break: break-all;
				}

				span.essential,
				span.optional {
					position: absolute;
					right: 10px;
				}
			}

			td {
				background-color: #fff;
				border-top: 1px solid $border-color;
				padding: 15px 20px;
				vertical-align: middle;

				@include mq() {
					padding: 10px 10px 20px 10px;
				}
			}

			tr {

				&:last-child th,
				&:last-child td {
					border-bottom: none;
				}

			}

			// 確認画面用のtable
			&.table_confirm {
				th {
					@include mq_up() {
						padding: 26px 20px;
					}
				}

				.txt_area {

					th,
					td {
						@include mq_up() {
							padding-bottom: 105px;
						}
					}
				}
			}
		}

	}

	div.lead_to_tel {
		background-color: $box;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px 0;
		margin-top: -19px;

		@include mq() {
			flex-direction: column;
			padding: 15px 0;
		}

		p {
			color: $main-color;
			font-size: 23px;
			margin-right: 55px;

			@include mq() {
				font-size: 18px;
				margin-right: 0;
				margin-bottom: 10px;
			}

			@include mq(ssp) {
				text-align: center;
			}
		}

		img {
			width: 331px;

			@include mq(ssp) {
				width: 100%;
				padding: 0 20px;
			}
		}
	}


	p {

		// アナウンステキスト
		&.head {
			text-align: center;
			padding: 45px 0 62px;
			@extend .wrapper;
		}
	}

	.confirm {
		.head {
			padding-top: 3px;
			padding-bottom: 28px;
		}
	}

	// 注釈
	span.arrow {
		font-size: 90%;
		margin: 5px 0;
		font-weight: bold;
		display: inline-block;
	}

	// ボタンを並べる
	ul.transmission {
		width: auto;
		text-align: center;
		margin: 20px auto 50px auto;
		display: table;

		@include mq() {
			margin-bottom: 30px;
		}

		@include mq(sp) {
			width: 95%;
			display: inherit;
		}

		li {
			display: table-cell;
			padding: 2px 5px;

			@include mq(sp) {
				display: block;
			}

			a {
				cursor: pointer; // href属性がないとポインタにならない
			}

		}
	}

	ul.transmission_confirm {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 60px 0 50px;

		@include mq() {
			margin: 40px 0 30px;
		}

		li {
			text-align: center;

			// &+li {
			// 	margin-top: 25px;

			// 	@include mq() {
			// 		margin-top: 15px;
			// 	}
			// }
		}

		form {
			text-align: center;
		}
	}

	// 項目を分割
	ul.break {
		width: 100%;
		display: table;

		@include mq(sp) {
			display: inherit;
		}

		li {
			display: table-cell;
			padding: 0 5px;

			&:nth-child(1) {
				padding: 0 5px 0 0;

				@include mq(sp) {
					padding: 0;
				}
			}

			&:last-child {
				padding: 0 0 0 5px;

				@include mq(sp) {
					padding: 0;
				}
			}

			@include mq(sp) {
				display: inherit;
				padding: 0;
			}
		}
	}

	// 項目を並べる
	ul.variable {
		width: 100%;

		li {
			display: inline-block;
			padding: 0 5px;

			&:last-child {
				padding: 0 0 0 5px;

				@include mq(sp) {
					padding: 0;
				}
			}

			&:nth-child(1) {
				padding: 0 5px 0 0;

				@include mq(sp) {
					padding: 0;
				}
			}

			@include mq(sp) {
				display: inherit;
				padding: 0;
			}

			input {
				width: auto;
				margin: 5px 10px 5px 0;
			}
		}
	}

	// チェックリストを並べる
	ul.check_list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		li {
			width: auto;
			min-width: 25%;
			padding: 5px 0;

			@media only screen and (max-width:1000px) {
				width: 100%;
				float: none;
				border-bottom: $border-color 1px solid;
			}
		}
	}

	// 郵便番号
	ul.number {
		display: table;

		li {
			display: table-cell;

			&:nth-child(1) {

				input {
					width: 5em;
				}
			}

			&:nth-child(2) {
				input {
					width: 7em;
				}
			}

			p {
				display: inline-block;
			}

			span {
				padding: 0 10px;
			}
		}
	}


	// 電話番号
	ul.phone {
		display: table;
		table-layout: auto;

		li {
			display: table-cell;

			&:nth-child(1) {

				input {
					width: 5em;
				}
			}

			&:nth-child(2) {
				input {
					width: 6em;
				}
			}

			&:nth-child(3) {
				input {
					width: 6em;
				}
			}

			p {
				display: inline-block;
			}

			span {
				padding: 0 10px;
			}
		}
	}

	// 完了画面のアナウンス
	div.comp_box {
		padding: 50px 0;

		@include mq(sp) {
			padding: 30px 0;
		}
	}
}


// エラーエリア
div.err_area {
	//margin: 5px auto;
	//padding: 5px 0;
	display: block;

	p {
		display: block;
		padding: 5px 10px;
		font-weight: bold;
		color: $err-color;
		border: $err-color 1px solid;
		margin: 5px auto 0;
		text-align: center !important;
		@extend .font_size_12;
		border-radius: 5px;
	}

	.text_box {
		width: 80%;
		margin: 5px auto;
		padding: 15px 0;
		display: block;

		li {
			display: block;
			padding: 5px 10px;
			font-weight: bold;
			color: $err-color;
			border: $err-color 1px solid;
			margin: 5px auto;
			text-align: center;
			@extend .font_size_12;
			border-radius: 5px;
		}
	}

	.list_box {
		width: 90%;
		margin: 0 auto 10px auto;
		padding: 5px 0;
		@extend .wrapper;

		li {
			display: block;
			padding: 5px 10px;
			font-weight: bold;
			color: $err-color;
			border: $err-color 2px solid;
			margin: 5px auto;
			text-align: center;
			border-radius: 5px;
		}
	}
}

// 確認画面、完了画面のエラー表示
div.error_area {
	@extend .wrapper;
	max-width: 800px;

	.error_access,
	.text_box,
	.list_box {
		margin: 0 auto 10px auto;
		padding: 5px 0;
		@extend .wrapper;

		li {
			display: block;
			padding: 5px 10px;
			font-weight: bold;
			color: $err-color;
			border: $err-color 2px solid;
			margin: 5px auto;
			text-align: center;
			border-radius: 5px;
		}

	}
}

// 繰り返し要素の追加ボタン、削除ボタン
.btn_add {
	padding: 5px 20px;
	border-radius: 5px;
	background-color: $main-color;
	@include link-color(#fff);
	display: inline-block;
	@extend .font_size_14;
	font-weight: 700;
	border: none;

	&:hover {
		text-decoration: none;
		opacity: 0.8;
	}

	span {
		padding-left: 12px;
		position: relative;
		display: inline-block;

		&:before {
			content: "+";
			position: absolute;
			left: 0;
			top: 3px;
			line-height: 1;
		}
	}
}

.btn_remove {
	@extend .btn_add;
	background-color: $box;
	@include link-color($base-font-color);

	span {
		&:before {
			content: "-";
		}
	}
}



p#fm_ajax_message {
	font-weight: 700;
	color: $main-color;
	letter-spacing: 1px;
	@extend .font_size_14;
}