@charset "utf-8";

// ------------ 記事一覧 ------------*/

// メイン LAYOUT
div.edit {

	// サイドバー LAYOUT
	aside {
		margin-top: 212px;

		@include mq() {
			margin-top: 20px;
		}

		&.editor {
			margin-top: 268px;

			@include mq() {
				margin-top: 65px;
			}
		}

		background-color: #fff;
		padding: 36px 27px 34px;

		@include mq() {
			padding-top: 10px;
		}

		.side_box {
			padding-bottom: 40px;

			@include mq(sp) {
				padding: 20px 0;
			}

			&:first-child {
				padding: 0 0 30px 0;

				@include mq(sp) {
					padding: 0 0 20px 0;
				}
			}

			&:last-child {
				border-bottom: none;
			}

		}

		// タイトル
		h2 {
			margin: 0 auto 10px auto;
			font-weight: $medium;
			@extend .font_size_22;
		}

		// アーカイブ
		.arrow_archive {
			position: relative;

			&::after {
				content: "";
				display: inline-block;
				width: 12px;
				height: 7px;
				background-image: url(../img/article/input_select.svg);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				right: 17px;
				transform: translateY(-50%);
			}
		}

		select.article_archive {
			width: 100%;
			padding: 15px;
		}

	}

}



// 記事のヘッダー
div.article_head {

	// タイトル
	h2 {
		font-weight: $medium;
		@extend .font_size_16;
	}

}



// カテゴリ一覧
ul.article_cgy {
	font-size: 0;

	li {
		margin-right: 10px;
		margin-bottom: 5px;
		display: inline-block;

		a,
		span {
			@extend .font_size_14;
			background-color: $acc-color;
			padding: 5px 9px 5px 10px;
			color: #fff;
			font-weight: $demibold;
		}

		a {
			@include link-color(#fff);

			&:hover {
				opacity: 0.8;
				text-decoration: none;
			}
		}

	}
}


// 投稿一覧（リスト型）
ul.list_article {
	background-color: #fff;
	padding: 44px 30px;
	margin-bottom: 28px;

	li {
		padding: 20px 0 15px;
		align-items: center;
		border-bottom: 1px solid $border-color;

		@include mq() {
			flex-wrap: wrap;
		}
	}

	div.info_text {
		padding-bottom: 20px;
	}

	p.date {
		width: 135px;
		color: $main-color;
		padding: 0 5px 0 0;
		@extend .font_size_16;
		display: inline-block;

		@include mq() {
			width: auto;
			// padding: 0 10px 10px 0;
		}
	}

	p.cat {
		width: 130px;
		padding: 0 10px 0 0;
		display: inline-block;

		a,
		span {
			color: #fff;
			background-color: $acc-color;
			padding: 5px 9px 5px 10px;
			@extend .font_size_12;
		}

		a {
			@include link-color($main-color);

			&:hover {
				text-decoration: none;
				background-color: $main-color;
				color: #fff;
			}
		}

		@include mq() {
			width: auto;
			// padding: 0 10px 10px 0;
		}
	}



	h2,
	h3 {
		padding-right: 26px;

		a {
			padding: 11px 22px 0 0;
			background-image: url(../img/arrow/arrow_right_c.svg);
			background-size: 7px 12px;
			background-repeat: no-repeat;
			background-position: right center;
		}


		@include mq() {
			width: 100%;
			margin-top: 10px;
		}
	}

	// タイトルの末尾3点リーダー
	&.textend_dots {

		h2,
		h3 {
			@include textend-dots(2, $base-line-height);

		}
	}
}


// Pager
ul.pager {
	text-align: center;
	padding: 0 0 80px;

	@include mq() {
		padding-bottom: 50px;
	}

	li {
		width: 36px;
		height: 36px;
		@extend .font_size_20;
		display: inline-block;
		letter-spacing: normal;
		vertical-align: middle;

		&.active {
			color: #fff;
			background-color: $acc-color;
			border-radius: 50%;

			@include mq() {
				padding: 2px;
			}

			@include mq(sp) {
				padding: 5px;
			}
		}

		a {
			display: block;
			width: 100%;
			height: 100%;

			@include mq() {
				padding: 2px;
			}

			@include mq(sp) {
				padding: 5px;
			}

			&.prev,
			&.next {
				height: 30px;
				background-repeat: no-repeat;
				background-size: auto 12px;
				background-position: center center;
			}

			&.prev {
				background-image: url(../img/article/arrow_left_c.svg);
			}

			&.next {
				background-image: url(../img/article/arrow_right_c.svg);
			}
		}
	}
}


// ------------ 記事 ------------*/

section.editor {
	margin-top: 62px;

	.article_head {

		ul {
			display: flex;
			align-items: center;

			.article_day {
				width: 120px;
				color: $main-color;
				padding: 0 10px 0 0;
				@extend .font_size_16;
				display: inline-block;

				@include mq() {
					width: auto;
					// padding: 0 10px 10px 0;
				}
			}

			.article_cgy {
				li {
					width: 130px;
					padding: 0 10px 0 0;
					display: inline-block;
				}
			}
		}

		h1 {
			@extend .font_size_40;
			margin-bottom: 40px;
		}
	}
}

// エディターエリア
div.editor {
	background-color: #fff;
	padding: 35px 28px 47px 32px;
	margin-bottom: 40px;
	@extend .font_size_16;

	@include mq(sp) {
		padding: 20px 15px;
	}

	// clearfix
	@extend %clearfix;

	// _editor.scssで定義したミックスインeditor()で
	// Gutenbergで登録した本文にあてるスタイルを呼び出す
	@include editor();

}

// 一覧に戻るボタン
.article_transfer {
	text-align: center;
	@extend .btn_return;
	margin-bottom: 47px;
}


//ページ送り
.page-links {
	font-weight: bold;
	text-align: center;

	@include mq() {
		text-align: center;
	}

	.post-page-numbers {
		background-color: #fff;
		font-size: 18px;
		padding: 5px 12px;
		text-decoration: none !important;
		border: 2px solid $main-color;
		margin: 0 3px;

		&:hover {
			background-color: $sub-color;
			border: 2px solid $sub-color;
			color: #fff !important;

		}

		&.current {
			background-color: $main-color;
			border: 2px solid $main-color;
			color: #fff !important;
		}
	}
}