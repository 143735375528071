@charset "utf-8";

// ------------ 下層ページ ------------


/* --------------------
	price.html、
	strength.htmlで使用
-------------------- */

.desc_area {
	max-width: 944px;
	background-color: $box;
	border-radius: 20px;

	.desc_wrapper {
		padding: 40px 54px 0;

		@include mq() {
			padding: 20px 20px 0;
		}

		&:last-of-type {
			padding-bottom: 40px;
		}

		.ttl {
			margin-bottom: 20px;

			@include mq() {
				text-align: center;
			}
		}

		.desc_box {
			background-color: rgba($color: #fff, $alpha: .8);
			border-radius: 20px;
			padding: 24px 47px 15px 35px;

			@include mq() {
				padding: 20px;
			}

			.desc_normal_txt {
				line-height: 1;
				padding: 20px 0;

				@include mq() {
					line-height: $base-line-height;
				}
			}

			.ttl_green {
				color: $sub-color;
			}

			.ttl_blue {
				color: #4875B2;
			}

			.namely {
				display: flex;
				align-items: center;

				@include mq() {
					flex-direction: column;
					// justify-content: center;
				}

				.to_circle {
					color: #fff;
					text-align: center;
					line-height: 1;
					width: 94px;
					height: 94px;
					background-color: $sub-color;
					border-radius: 50%;
					padding: 38px 0;

					@include mq() {
						margin-bottom: 20px;
					}
				}

				p {
					color: $main-color;
					@extend .font_size_22;
					padding-left: 15px;

					@include mq() {
						padding-left: 0;
					}

					span {
						color: $base-font-color;
						@extend .font_size_18;
						padding: 0 13px;

						@include mq() {
							padding: 0 7px;
						}
					}
				}
			}
		}
	}
}

/* --------------------
	price.htmlで使用
-------------------- */

.sec.table_area {
	padding-bottom: 0;
}

.table_area {
	max-width: 830px;
	padding-top: 50px;
	margin-left: 58px;

	@include mq() {
		margin-left: 0;
	}

	.table_wrapper {
		margin-bottom: 50px;

		&:last-of-type {
			margin-bottom: 19px;
		}

		h3 {
			@extend .font_size_20;
			margin-bottom: 19px;
		}

		.table_group {
			border-collapse: collapse;

			// grid table 表全てのスタイル
			.table_box {
				border-top: 1px solid $border-color;

				&:nth-child(odd) {
					background-color: rgba($color: $box, $alpha: .32);
				}

				&:nth-child(even) {
					background-color: #fdfcf6;
				}

				.cat {
					width: 115px;
					@extend .font_size_20;
					padding-left: 14px;
				}

				.box_item {
					width: max-content;

					>p {
						display: inline-block;
					}
				}

				.cat_sub {
					@extend .font_size_16;
					padding-right: 10px;
				}

				.price {
					@extend .font_size_20;
					font-weight: $bold;
					color: $sub-color;
				}
			}


			div.table_box {

				//中学部
				&.box_jhs1,
				&.box_jhs2,
				&.box_jhs3 {
					display: grid;
					grid-template-columns: 110px 195px 195px;
					grid-template-rows: 60px;

					.item1,
					.item2,
					.item3 {
						padding-top: 13px;
					}
				}

				@include mq(sp) {

					&.box_jhs1,
					&.box_jhs2,
					&.box_jhs3 {
						grid-template-columns: 110px 195px;
						grid-template-rows: repeat(2, 50px);

						.item1 {
							grid-area: 1 / 1 / 3 / 2;
						}

						.item2 {
							grid-area: 1 / 2 / 2 / 3;
						}

						.item3 {
							grid-area: 2 / 2 / 3 / 3;
							padding-top: 8px;
						}
					}
				}
			}

			// 小4
			.table_box.box_es4 {
				display: grid;
				grid-template-columns: 110px 220px 220px;
				grid-template-rows: 60px;

				.item1,
				.item2,
				.item3 {
					padding-top: 14px;
				}

				@include mq() {
					grid-template-columns: 110px 220px 220px;
				}
			}

			@include mq(sp) {
				.table_box.box_es4 {
					grid-template-columns: 110px 195px;
					grid-template-rows: repeat(2, 50px);


					.item1 {
						grid-area: 1 / 1 / 3 / 2;
					}

					.item2 {
						grid-area: 1 / 2 / 2 / 3;
					}

					.item3 {
						grid-area: 2 / 2 / 3 / 3;
						padding-top: 8px;
					}
				}

			}


			// 小5
			.table_box.box_es5 {
				display: grid;
				grid-template-columns: 110px 220px 230px repeat(2, 1fr);
				grid-template-rows: repeat(2, 51px) repeat(3, 1fr);

				.item1 {
					grid-area: 1 / 1 / 3 / 2;
					padding-top: 14px;
				}

				.item2 {
					grid-area: 1 / 2 / 2 / 3;
					padding-top: 14px;
				}

				.item3 {
					grid-area: 1 / 3 / 2 / 4;
					padding-top: 14px;
				}

				.item4 {
					grid-area: 2 / 2 / 3 / 3;
					padding-top: 5px;
				}

				.item5 {
					grid-area: 2 / 3 / 3 / 4;
					padding-top: 5px;
				}
			}


			@include mq(sp) {
				.table_box.box_es5 {
					grid-template-columns: 110px 210px;
					grid-template-rows: repeat(4, 50px);

					.item1 {
						grid-area: 1 / 1 / 5 / 2;
					}

					.item2 {
						grid-area: 1 / 2 / 2 / 3;
					}

					.item3 {
						grid-area: 2 / 2 / 3 / 3;
						padding-top: 8px;
					}

					.item4 {
						grid-area: 3 / 2 / 4 / 3;
						padding-top: 8px;
					}

					.item5 {
						grid-area: 4 / 2 / 5 / 3;
						padding-top: 8px;
					}
				}
			}


			// 小6
			.table_box.box_es6 {
				display: grid;
				grid-template-columns: 110px 258px 200px 170px;
				grid-template-rows: repeat(2, 52px);

				.item1 {
					grid-area: 1 / 1 / 3 / 2;
					padding-top: 14px;
				}

				.item2 {
					grid-area: 1 / 2 / 2 / 3;
					padding-top: 14px;
				}

				.item3 {
					grid-area: 1 / 3 / 2 / 4;
					padding-top: 14px;
				}

				.item4 {
					grid-area: 1 / 4 / 2 / 5;
					margin-left: -10px;
					padding-top: 14px;
				}

				.item5 {
					grid-area: 2 / 2 / 3 / 3;
					padding-top: 5px;

					.cat_sub {
						padding-right: 40px;
					}
				}

				.item6 {
					grid-area: 2 / 3 / 3 / 4;
					padding-top: 5px;

					.price {
						padding-right: 0;
					}
				}
			}


			@include mq() {
				.table_box.box_es6 {
					grid-template-columns: 110px 220px 220px;
					grid-template-rows: repeat(3, 52px);

					.item1 {
						grid-area: 1 / 1 / 4 / 2;
					}

					.item2 {
						grid-area: 1 / 2 / 2 / 3;
					}

					.item3 {
						grid-area: 1 / 3 / 2 / 4;
					}

					.item4 {
						grid-area: 2 / 2 / 3 / 3;
						margin-left: 0;
						padding-top: 5px;
					}

					.item5 {
						grid-area: 2 / 3 / 3 / 4;
						padding-top: 5px;

						.cat_sub {
							padding-right: 10px;
						}
					}

					.item6 {
						grid-area: 3 / 2 / 4 / 3;
					}
				}
			}

			@include mq(sp) {
				.table_box.box_es6 {
					grid-template-columns: 110px 210px;
					grid-template-rows: repeat(5, 50px);


					.item1 {
						grid-area: 1 / 1 / 6 / 2;
					}

					.item2 {
						grid-area: 1 / 2 / 2 / 3;
					}

					.item3 {
						grid-area: 2 / 2 / 3 / 3;
						padding-top: 8px;
					}

					.item4 {
						grid-area: 3 / 2 / 4 / 3;
						padding-top: 8px;
					}

					.item5 {
						grid-area: 4 / 2 / 5 / 3;
						padding-top: 8px;
					}

					.item6 {
						grid-area: 5 / 2 / 6 / 3;
						padding-top: 8px;
					}
				}
			}

			//小学部
			.box_es4,
			.box_es5,
			.box_es6 {
				.cat_sub {
					padding-right: 10px;
				}

				.price {
					padding-right: 30px;
				}
			}

		}

		// 夏期講習の追加スタイル
		table.table_group {

			.table_box {

				.cat,
				.price {
					padding-top: 13px;
					padding-bottom: 14px;
				}
			}
		}
	}
}



.note {
	max-width: 830px;
	background-color: #fff;
	border-radius: 10px;
	@extend .font_size_14;
	padding: 20px;
	margin-top: 50px;
}



/* --------------------
	access.htmlで使用
-------------------- */
.area_inner {
	max-width: 944px;
}

.map_area {
	background-color: #fff;
	border-radius: 20px;

	.map_wrapper {
		padding: 39px 38px 47px 42px;

		@include mq() {
			padding: 20px 24px 30px;
		}

		.ttl_address {
			display: inline-block;
			@extend .font_size_28;
			padding-right: 44px;
		}

		.txt_address {
			display: inline-block;
		}

		.map {
			width: 100%;
			height: 393px;
			position: relative;
			margin-top: 22px;

			@include mq() {
				height: 158px;
			}

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

}

.access_area {

	.access_wrapper {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $border-color;
		padding-bottom: 35px;
		margin-top: 71px;
		margin-left: 42px;

		@include mq() {
			flex-direction: column;
			justify-content: center;
			margin-top: 40px;
			margin-left: 0;
		}

		.img {
			width: 350px;
			margin-right: 54px;

			@include mq() {
				width: 60%;
				margin-right: 0;
			}
		}

		.access_table {
			width: auto;

			@include mq() {
				margin-top: 25px;
			}

			.item_ttl,
			.item_txt {
				padding-bottom: 35px;

				@include mq() {
					padding-bottom: 12px;
				}
			}

			.padding_none {
				padding-bottom: 0;
			}

			.item_ttl {
				@extend .font_size_18;
				line-height: 0;
				text-align: left;
				width: 102px;
				height: 64px;
			}

			.item_txt {
				@extend .font_size_26;
				width: auto;

				img {
					height: 64px;

					@include mq() {
						height: 42px;

					}
				}
			}
		}
	}
}

.route_area {
	display: flex;

	@include mq() {
		flex-direction: column-reverse;
	}

	.route_txt_wrapper {
		margin: 37px 0 30px 70px;

		@include mq() {
			margin: 12px 0 30px 0;
		}

		// 見出し共通設定
		.ttl_route {
			font-size: 21px;
			font-weight: $demibold;
			color: $acc-color;
			padding-left: 30px;
			margin-bottom: 20px;
			position: relative;

			&::before {
				content: "";
				width: 24px;
				height: 28px;
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		// バスの見出し設定
		.route_bus {
			.ttl_route {
				&::before {
					background-image: url(../img/lower/icon_bus.svg);
					left: 0;
				}
			}
		}

		// ○分以内で行ける学校の見出し設定
		.route_10min,
		.route_15min {
			.ttl_route {
				&::before {
					background-image: url(../img/lower/icon_walking.svg);
					left: 3px;
				}

				span {
					color: $base-font-color;
					@extend .font_size_16;

					@include mq() {
						display: block;
						margin-top: -6px;
					}
				}
			}

			.route_box {
				&:not(:last-of-type) {
					margin-bottom: 15px;
				}

			}
		}

		.route_bus {
			margin-bottom: 30px;
		}

		.route_10min {
			margin-bottom: 45px;
		}

		.route_10min,
		.route_15min {

			h4 {
				letter-spacing: 0.1em;
				font-weight: $demibold;
			}

			ul {
				max-width: 471px;
				background-color: $box;
				border-radius: 10px;
				padding: 15px 20px;
				display: flex;
				flex-wrap: wrap;

				li {
					padding-right: 1em;

				}
			}
		}

	}

	.route_img_wrapper {
		margin-top: 62px;
		margin-left: 34px;

		@include mq() {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 40px;
			margin-left: 0;
			gap: 0 15px;
		}

		img {
			width: 280px;
			margin-bottom: 30px;

			@include mq() {
				width: 50%;
			}

		}
	}

}