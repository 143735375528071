@charset "utf-8";

// ------------ BASS ------------

html,
body {
	width: 100%;
	height: 100%;

	// PC表示は幅を最低限固定
	min-width: 1000px;
	margin-left: auto;
	margin-right: auto;

	@include mq() {
		min-width: inherit;
	}
}

body {
	font-family: $base-font-family;
	color: $base-font-color;
	@extend .font_size_16;
}

div#container {
	width: 100%;
	height: auto;
}

div.inner {
	max-width: 1150px;
	padding: 0 20px;
	margin: 0 auto;
	box-sizing: content-box;
}

// 選択テキストのカラー
::selection {
	background: $main-color;
	color: #FFF;
}

::-moz-selection {
	background: $main-color;
	color: #FFF;
}


// ------------ BASS STYLE ------------


// リンク設定
a {
	@include link-color($base-font-color);
	transition: background-color 0.4s ease, color 0.4s ease, opacity 0.4s ease;

	img {
		transition: opacity 0.4s ease;
	}

	&:hover {
		opacity: 0.8;

		img {
			opacity: 0.8;
		}
	}
}


// TABLE LAYOUT
table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;

	// table用クラス：~768pxでは縦1列表示にする
	&.tb_block {
		@include mq() {

			&,
			thead,
			tbody,
			tfoot,
			tr {
				display: block;
				width: 100%;
			}

			tr {

				th,
				td {
					display: block;
					width: 100%;
				}
			}

		}
	}

	// table用クラス：~568pxでは縦1列表示にする
	&.sp_block {
		@include mq(sp) {

			&,
			thead,
			tbody,
			tfoot,
			tr {
				display: block;
				width: 100%;
			}

			tr {

				th,
				td {
					display: block;
					width: 100%;
				}
			}

		}
	}

}

picture {
	display: block;
}

br {
	line-height: inherit;
}


// 斜線
hr {
	height: 1px;
	clear: both;
	border: none;
	border-top: $border-color 1px solid;
	color: #FFF;
}



// ------------ PARTS STYLE ------------

// clearfix
%clearfix {
	zoom: 1;

	&:after {
		display: block;
		content: "";
		clear: both;
	}
}


// ブロック要素のリンク
%a_block {
	width: 100%;
	height: 100%;
	display: block;
}


// ベースのコンテンツ幅
.wrapper {
	width: 95%;
	max-width: 1200px;
	margin: auto;
	position: relative;

	// clearfix
	@extend %clearfix;

}


// リンクエフェクト
.transitions {
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;

	@include mq() {
		transition: all 0s ease;
		-webkit-transition: all 0s ease;
		-moz-transition: all 0s ease;
		-o-transition: all 0s ease;
	}
}

// モーションエフェクト
.all_transitions {
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
}

// 上下中央に配置
.vertical {
	position: absolute;
	top: 50%;
	-webkit-transform: translate(0%, -50%);
	transform: translate(0%, -50%);
}

//高さ指定して中心でトリミング
.obj_image {
	object-fit: cover;
	font-family: 'object-fit: cover;'
}

// TITLE
%ttl {
	font-weight: $medium;
	@extend .font_size_40;
}

// 共通セクションタイトル
div.ttl,
h2.ttl {
	@extend %ttl;
	padding-top: 29px;
	padding-bottom: 69px;

	@include mq(sp) {
		padding-top: 0;
	}

	.ttl_sub {
		display: block;
		@extend .font_size_26;
		color: $main-color;
	}
}

h3.ttl {
	@extend %ttl;
	@extend .font_size_24;
}

// CVボタン
div#cv_fade {
	width: 316px;
	height: 316px;
	position: fixed;
	bottom: 10%;
	right: 0;
	z-index: 100;

	@include mq() {
		width: 204px;
		height: 204px;
		right: -18px;
	}

	a {
		img {
			width: 100%;
			height: 100%;
		}
	}
}


// BUTTON
%btn {

	// リンク
	a {
		display: inline-block;
		min-width: 180px;
		padding: 15px 60px 15px 30px;
		font-weight: bold;
		background-color: $acc-color;
		border-radius: 5px;
		@include link-color(#FFF);
		position: relative;

		&::after {
			content: "";
			width: 6px;
			height: 12px;
			background-image: url(../img/arrow/arrow_right_w.svg);
			background-size: contain;
			background-repeat: no-repeat;
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translateY(-50%);
		}

		&:hover {
			opacity: 0.8;
		}

		@include mq(sp) {
			min-width: 0;
		}
	}

}

.btn {
	@extend %btn;
}

.btn_return {

	// リンク
	a {
		display: inline-block;
		min-width: 270px;
		padding: 24px 0px 24px 30px;
		@extend .font_size_24;
		line-height: 1;
		font-weight: $demibold;
		background-color: $acc-color;
		border-radius: 5px;
		@include link-color(#FFF);
		position: relative;

		&::before {
			content: "";
			width: 9px;
			height: 16px;
			background-image: url(../img/arrow/arrow_left_w.svg);
			background-size: contain;
			background-repeat: no-repeat;
			position: absolute;
			top: 50%;
			left: 60px;
			transform: translateY(-50%);
		}

		&:hover {
			opacity: 0.8;
		}

		@include mq(sp) {
			min-width: auto;
			margin: 0 auto;
			max-width: 205px;
			display: block;
			padding: 18px 0 18px 30px;

			&::before {
				left: 40px;
			}
		}
	}
}


// 背景
.bg_body {
	width: 100%;
	height: auto;
	background-image: url(../img/common/bg_top_pc.png), url(../img/common/bg_body.png);
	background-size: cover, 600px;
	background-repeat: no-repeat, repeat;
	background-position: top center, center;

	@include mq(ssp) {
		background-image: url(../img/common/bg_top_sp.png), url(../img/common/bg_body.png);
	}
}

// テキスト

.emphasis {
	color: $main-color;
}