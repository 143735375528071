@charset "UTF-8";

// ---- テーマの基本スタイル ----

// メインカラー
$main-color: #A56161;
$sub-color: #69966E;
$acc-color: #817E28;
$err-color: #de2636; // フォームのエラー表示の色として設定

// 標準背景色
$bg-color: #EEEEEE; // フッターやセクションの背景色としてよく使用する色を設定

// 標準フォントカラー
$base-font-color: #3f3f3f;
$cat-font-color: #724415;

// フォント指定
$base-font-family: "A1明朝", 游明朝, "Yu Mincho", YuMincho, HGS明朝E, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;

// フォントの太さ
$regular: 400;
$medium: 500;
$demibold: 600;
$bold: 700;

// 標準ボーダーカラー
$border-color: #CCCCCC; // フォームのテーブル、hrなどのボーダーカラー

// ハンバーガーアイコンのカラー
$ham-color: #3f3f3f;

// ボックス
$box: #F4F0D4; // フォームのテーブルthの背景色など

// 行間（_reset.scssで使用）
$base-line-height: 1.7em;