@charset "utf-8";

// ------------ BASS LAYOUT ------------


// 1カラムレイアウト設定
// TOP含む全ページ共通
.sec {
	padding-bottom: 80px;

	&:last-child {
		padding-bottom: 80px;
	}

	&:first-child {

		@include mq() {
			padding-top: 30px;
		}
	}

}

// 2カラムレイアウト設定
// お知らせページで使用
div.column_2 {
	width: 95%;
	max-width: 1110px;
	margin: auto;
	padding: 30px 20px 0;
	position: relative;

	@include mq(ssp) {
		padding: 30px 10px 0;
	}

	// clearfix
	@extend %clearfix;

	// メインコンテンツ
	main {
		width: 68.5%;
		float: left;

		@include mq() {
			width: 100%;
			float: none;
		}

	}

	.sec {
		padding: 0;
	}

	// サイドバー 右に配置する設定
	// スタイルは_wp.scss
	aside {
		width: 25%;
		float: right;

		@include mq() {
			width: 100%;
			float: none;
		}
	}
}

// パンくずリスト
div.pan {
	width: 100%;
	// height: 3.7em;
	margin: 0;
	padding-bottom: 1em;
	position: relative;
	@extend .font_size_12;

	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;

	div.in {
		width: 95%;
		max-width: 1336px;
		padding: 0 20px;
		margin: auto;
		position: relative;

		// clearfix
		@extend %clearfix;
		padding: 0 5px;
	}


	#breadcrumbs {
		margin: auto;
		white-space: nowrap;

		a {
			color: $base-font-color;

			&:not(:last-of-type) {
				background-image: url(../img/arrow/arrow_right_c.svg);
				background-position: center right 5px;
				background-size: 13px 13px;
				padding: 0 22px 0 0;
				font-weight: bold;
			}

			&:last-of-type {
				pointer-events: none;
			}
		}

		img {
			margin: 1px 5px;
			vertical-align: text-bottom;
			width: 14px;
			height: 13px;
		}
	}
}



// ------------ CONTENT STYLE ------------

// メッセージボックス（フォームの送信完了画面と404ページで使用）
div.basket_box {
	max-width: 970px;
	background-color: #fff;
	height: auto;
	margin: auto;
	text-align: center;
	padding: 100px 0;

	@include mq(sp) {
		padding: 50px 0;
	}

	h1,
	h2 {
		margin: 0 0 35px 0;
		text-align: center;
		color: $sub-color;
		@extend .font_size_30;

		@include mq(ssp) {
			font-size: 20px;
		}
	}

	p {
		padding: 0 0 38px;
		@extend .font_size_16;
	}

	.btn_return {
		margin-top: 16px;

	}
}


// 404ページ
section.sec.n_found {
	padding: 0 20px;
}

div.n_found {
	padding: 50px 20px;


	h2 {
		color: $main-color;
	}
}