@charset "utf-8";

// ------------ FOOTER ------------*/
// footerのスタイル全てここで定義


footer {
	width: 100%;
	height: auto;
	background-image: url(../img/common/bg_body.png);
	background-size: contain;
	background-repeat: repeat;
	background-position: center;

	.links_inner {
		max-width: 1088px;
		padding: 0 20px 30px;
		margin: 0 auto;
		box-sizing: content-box;

		@include mq(sp) {
			padding: 0 30px calc(46px - 13.5px);
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 0 5%;

			@include mq(sp) {
				flex-direction: column;
				justify-content: center;
			}

			li {
				.banner_record {
					width: 100%;
					max-width: 381px;

					@include mq() {
						width: 331px;
						max-width: 100%;
					}

					@include mq(sp) {
						width: 315px;
						padding-bottom: 32px;
					}
				}

				.banner_telephone {
					width: 100%;
					max-width: 331px;

					@include mq() {
						max-width: 100%;
						width: 281px;
					}

					@include mq(sp) {
						width: 315px;
					}
				}

				.banner_mail {
					width: 100%;
					max-width: 248px;

					@include mq() {
						max-width: 100%;
						width: 298px;
					}

					@include mq(sp) {
						width: 315px;
					}
				}

				@include mq(sp) {

					&:not(:first-child) {
						position: relative;

						&::before {
							content: "";
							width: 100%;
							height: 1px;
							background-image: url(../img/common/line_banner.svg);
							background-position: center bottom;
							position: absolute;
							bottom: 0;
						}
					}
				}
			}
		}
	}


	// フッターナビゲーション
	.footer_navigation {
		max-width: 100%;
		width: 100%;
		height: auto;
		background-image: url(../img/common/bg_footer_pc.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		@extend .wrapper;
		position: relative;
		padding-top: 90px;

		@include mq() {
			padding-top: 0;
		}

		@include mq(sp) {
			background-image: url(../img/common/bg_footer_sp.png);
			background-size: contain;
			background-position: bottom center;
		}

		.footer_navigation_wrapper {
			max-width: 1107px;
			padding: 0 20px 30px;
			margin: 0 auto;
			box-sizing: content-box;
			position: relative;

			@include mq() {
				display: flex;
				flex-direction: column-reverse;
			}

			// Logo
			div.logo {
				padding: 0;

				@include mq() {
					text-align: center;
				}

				img {
					width: 236px;
					height: auto;
					margin: auto;
				}
			}

			a {
				@include link-color(#666);

			}

			div.navigation {
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 35px;
				transform: translateY(-50%);

				@include mq() {
					display: block;
					position: static;
					transform: translateY(0);
				}

				>ul {
					padding: 0 10px;
					// clearfix
					@extend %clearfix;

					@include mq() {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-wrap: wrap;
						padding: 0 0 100px;
					}

					>li {
						float: left;
						font-size: 16px;

						@include mq() {
							width: 10rem;
						}

						a {
							display: block;
							width: 100%;
							height: 100%;
							padding: 35px 15px;
							font-weight: $medium;

							@include mq() {
								text-align: center;
								padding: 13.5px 0;
							}

							// アクティビティ
							&.active {
								font-weight: $bold;
							}
						}

						.nav_record {
							@include mq() {
								margin-left: 0;

								&::before {
									left: 30px;
								}
							}
						}
					}
				}
			}
		}
	}

	// ドキュメント
	.footer_document {
		max-width: 1107px;
		padding: 0 20px 41px;
		margin: 0 auto;
		box-sizing: content-box;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@include mq() {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-bottom: 30px;
		}

		div.address {

			p {
				@extend .font_size_14;
				text-align: left;

				@include mq() {
					text-align: center;
					margin-bottom: 46px;
				}
			}
		}

		div.copyright {

			p {
				@extend .font_size_12;
				text-align: right;

				@include mq() {
					text-align: center;
				}
			}
		}
	}
}