@charset "utf-8";

// ------------ FRONT ------------

.top_content {
	padding-bottom: 80px;

	@include mq() {
		padding-bottom: 30px;
	}

	.ttl_front {
		@extend %ttl;
		font-size: 46px;
		padding-top: 89px;
		margin-bottom: 7px;

		@include mq() {
			font-size: 28px;
			padding-top: 40px;
			padding-left: 13px;
			margin-bottom: 10px;
		}
	}

	.ttl_front_lead {
		font-size: 23px;
		color: #C46361;
		margin-bottom: 98px;

		@include mq() {
			font-size: 18px;
			margin-bottom: 103px;
			padding-left: 13px;
		}

		@include mq(sp) {
			font-size: 14px;
		}
	}

	.about_wrapper {
		@include mq(pc) {
			text-align: center;
		}
	}

	.about_area {
		background-color: $box;
		border-radius: 20px;
		padding: 54px 41px 35px 61px;

		@include mq(pc) {
			display: inline-block;
		}

		@include mq() {
			padding: 29px 10% 10px;


		}

		@include mq(sp) {
			padding: 29px 10px 10px;
			display: block;
		}

		.ttl {
			@extend %ttl;
			@extend .font_size_28;

			@include mq(pc) {
				text-align: center;
				padding-bottom: 10px;
			}
		}

		.about_grid {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			gap: 17px 12px;

			@include mq(pc) {
				max-width: 650px;
				margin: 0 auto;
			}

			@media screen and (max-width:703px) {
				flex-direction: column;

			}

			.grid_item_wide {
				width: 518px;
				height: 280px;
				padding: 40px 50px 45px;
				text-align: left;

				@include mq(pc) {
					width: 316px;
					height: 285px;
					padding: 32px 32px 26px;
				}
			}

			.grid_item_narrow {
				width: 518px;
				height: 230px;
				padding: 40px 50px 35px;
				text-align: left;

				@include mq(pc) {
					width: 316px;
					height: 226px;
					padding: 32px 32px 26px;
				}
			}

			.grid_item_wide,
			.grid_item_narrow {

				h4 {
					font-size: 24px;
					color: $sub-color;

					@include mq(pc) {
						font-size: 19px;
						line-height: calc(25 / 19);
						text-align: center;
					}
				}

				p {
					font-size: 16px;

					@include mq(pc) {
						font-size: 14px;
					}
				}
			}
		}
	}
}

%frame {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;

	@include mq(pc) {
		background-size: contain;
	}
}

.frame_001 {
	@extend %frame;
	background-image: url(../img/front/frame_001_pc.png);

	@include mq(pc) {
		background-image: url(../img/front/frame_001_sp.png);
	}
}

.frame_002 {
	@extend %frame;
	background-image: url(../img/front/frame_002_pc.png);

	@include mq(pc) {
		background-image: url(../img/front/frame_002_sp.png);
	}
}

.frame_003 {
	@extend %frame;
	background-image: url(../img/front/frame_003_pc.png);

	@include mq(pc) {
		background-image: url(../img/front/frame_003_sp.png);
	}
}

.frame_004 {
	@extend %frame;
	background-image: url(../img/front/frame_004_pc.png);

	@include mq(pc) {
		background-image: url(../img/front/frame_004_sp.png);
	}
}

// 価格について

.price_area {

	.price_box {
		border-radius: 20px;
		background-color: #fff;
		background-image: url(../img/front/bg_price_pc.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center right;
		padding: 37px 68px;

		@include mq() {
			background-position: top center;
			padding: 127px 20px 17px;
		}


		.price_description {
			padding-bottom: 22px;

			.ttl {
				padding: 0;
				font-size: 30px;

				.ttl_sub {
					font-size: 18px;
					color: $main-color;
					display: block;
				}
			}
		}
	}
}

div.contents_sbs {
	@extend .inner;
	display: flex;
	justify-content: center;
	align-items: stretch;

	@include mq() {
		flex-direction: column;
		align-items: center;
		padding: 0 30px;
		margin-bottom: 34px;
	}
}

// コラム
.column_area {

	.ttl {
		padding-top: 0;
		padding-bottom: 20px;
		font-size: 33px;

		@include mq() {
			font-size: 22px;
			padding-bottom: 30px;
		}
	}

	// _wp.scssのスタイルをTOP用に上書き
	ul.list_article {
		background-color: transparent;
		padding: 0 44px 0 0;
		margin-bottom: 100px;

		@include mq() {
			padding: 0;
			margin-bottom: 42px;
		}

		li {
			border: none;
			position: relative;

			&::before {
				content: "";
				width: 100%;
				height: 1px;
				background-image: url(../img/common/line_column.svg);
				background-position: center bottom;
				position: absolute;
				bottom: 0;
			}

			@include mq() {
				flex-wrap: wrap;

				&:first-child {
					padding-top: 0;
				}
			}
		}

		div.info_text {
			padding-bottom: 15px;
			position: relative;

			@include mq() {
				padding-bottom: 0;
			}
		}

		p.cat {
			width: 130px;
			padding: 0 10px 0 0;
			display: inline-block;

			a,
			span {
				@extend .font_size_14;

			}
		}

		h2 {
			padding-right: 26px;

			a {
				padding-top: 0;
				padding-right: 0;
				background-image: none;
			}


			@include mq() {
				width: 100%;
				margin-top: 10px;
			}
		}

		// タイトルの末尾3点リーダー
		&.textend_dots {

			h2 {
				@include textend-dots(1, $base-line-height);

				@include mq() {
					@include textend-dots(2, $base-line-height);
				}
			}
		}
	}
}

// twitter
.twitter_area {
	width: 480px;

	@include mq() {
		width: 100%;
	}
}