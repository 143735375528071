@charset "utf-8";

//後ろの要素固定
body {
	&.drawer_open {
		overflow: hidden;
		-ms-touch-action: none;
		touch-action: none;
	}
}

// SPのみ電話アイコン
.icon_tel {
	@include mq(pc) {
		position: absolute;
		top: 0;
		right: 52px;
		padding-top: 14px;
		display: block;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		background-color: transparent;
	}

	img {
		width: 30px;
		height: 28px;
	}
}


// ハンバーガーの設定
.toggle_button {
	display: none;

	@include mq(pc) {
		position: absolute;
		z-index: 1001;
		top: 0;
		right: -10px;
		display: block;
		box-sizing: content-box;
		width: 30px;
		padding: 15px 12px 15px;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		border: 0;
		outline: 0;
		background-color: transparent;
	}

	//ハンバーガーボタン
	.bar {
		background-color: $ham-color;
		display: block;
		width: 100%;
		height: 2px;
		border: 0;
		-webkit-transition: background-color .35s ease, transform .35s ease;
		transition: background-color .35s ease, transform .35s ease;
	}

	.bar+.bar {
		margin-top: 9px;
	}

	//閉じるボタン
	&.toggle_active {
		.bar {
			background-color: $ham-color;
		}

		.top {
			-webkit-transform: translateY(15px) translateX(0) rotate(45deg);
			transform: translateY(15px) translateX(0) rotate(45deg);
			width: 30px;
		}

		.middle {
			opacity: 0;
		}

		.bottom {
			-webkit-transform: translateY(-7px) translateX(0) rotate(-45deg);
			transform: translateY(-7px) translateX(0) rotate(-45deg);
			width: 30px;
			margin-left: 0;
		}
	}
}

//開いた時の背景
.drawer_bg {
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 999;
	top: 0;
	right: 0;
	background-color: rgba(51, 51, 51, 0.8);
}

nav {

	@include mq(pc) {
		display: none;
		opacity: 0;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-overflow-scrolling: touch;

		//開く時の動き
		&.is_open {
			display: block;
			top: 0;
			opacity: 1;
			animation-duration: 0.4s;
			//アニメーションの種類
			animation-name: fadeInRight;
		}

		//閉じる時の動き（何もしないとパッと閉じる）
		&.is_close {
			animation-duration: 0.4s;
			//アニメーションの種類
			animation-name: fadeOutRight;

		}
	}
}


/**
* drawer animation
*/

//右から
@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeOutRight {
	from {
		opacity: 0;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(100%, 0, 0);
	}
}