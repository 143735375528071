@charset "utf-8";

// ------------ NAV LAYOUT ------------

//共通

.nav_record {
	position: relative;
	margin-left: 20px;

	&::before {
		content: "";
		width: 18px;
		height: 14px;
		background-image: url(../img/common/icon_sakura.svg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		top: 50%;
		left: -4px;
		transform: translateY(-50%);
	}
}

//header

header {

	nav {
		padding-right: 273px;
		margin: auto;
		position: absolute;
		right: 0;
		@extend .vertical;
		z-index: 1;

		// 折りたたみ時
		@include mq(pc) {
			padding-right: 0;
			margin: 0 0 0 auto;
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			width: 70%;
			overflow: auto;
			background-color: #fff;
			z-index: 1000;
			-webkit-transform: none;
			transform: none;
		}
	}

	.navigation {

		>ul {
			padding: 0 10px;

			// clearfix
			@extend %clearfix;

			// 折りたたみ時
			@include mq(pc) {
				width: 100%;
				padding: 20px 10%;
			}

			>li {
				float: left;

				// 折りたたみ時
				@include mq(pc) {
					width: 100%;
					float: none;
					border-bottom: #ececec 1px solid;

					&:last-child {
						border-bottom: none;
					}
				}

				a {
					display: block;
					width: 100%;
					height: 100%;
					padding: 35px 15px;
					font-weight: $medium;

					// 折りたたみ時
					@include mq(pc) {
						padding: 15px;
					}

					// アクティビティ
					&.active {
						font-weight: $bold;
					}
				}

				&.switching {
					display: none;

					// 折りたたみ時
					@include mq(pc) {
						display: inherit;
					}

					// ロゴ
					img {
						width: 100%;
						max-width: 250px;
						height: auto;
						margin: 20px auto 10px auto;
					}
				}

				.nav_cv {
					img {
						width: 100%;
						max-width: 220px;
						padding: 10px 0;
						margin: 0 auto;
					}
				}
			}
		}
	}
}