@charset "utf-8";

// ------------ FORM BASS ------------
input,
select,
textarea,
search {
	font-family: $base-font-family;
	border: $border-color 1px solid;
	background-color: #FFF;
	-webkit-appearance: none;
	font-size: 16px; // SPでは16px以上を指定すること（フォーカス時のズーム防止）
	border-radius: 0;
}

button {
	font-family: $base-font-family;
	background-color: transparent;
	@extend .font_size_16;
	border: none;
}

// FOCUS
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: 0;
	border-color: $main-color;
}

// PLACEHOLDER
:-ms-input-placeholder {
	color: #ddd;
}

::placeholder {
	color: #ddd;
}


// google
::selection {
	color: #fff;
	background: $main-color;
}

input::selection,
textarea::selection {
	color: #fff;
	background: $main-color;
}


// ------------ INPUT STYLE ------------

input {
	width: 100%;
	padding: 10px;
}


// // 長さ設定
// input.wth_60 {
// 	width: 60%;

// 	@include mq() {
// 		width: 100%;
// 	}
// }

// テキストエリア
textarea {
	width: 100%;
	height: 12em;
	padding: 10px;
	resize: vertical;
}

// IE
select::-ms-expand {
	display: none;
}


// ラジオボタン チェックボックス
input[type="radio"],
input[type="checkbox"] {
	display: none;
}

// 未選択時
input[type="radio"]+label {
	padding: 7px 0px 7px 25px;
	margin: 2px 20px 2px 0px;
	display: inline-block;
	background-image: url(../img/arrow/input_check_off.svg);
	background-position: left 50%;
	background-size: 18px 18px;
	cursor: pointer;
}

// 選択時
input[type="radio"]:checked+label {
	background-image: url(../img/arrow/input_check_on.svg);
	background-position: left 50%;
	background-size: 18px 18px;
}


// 未選択時
input[type="checkbox"]+label {
	padding: 7px 0px 7px 25px;
	margin: 2px 20px 2px 0px;
	background-image: url(../img/arrow/checkbox_off.svg);
	background-position: left 50%;
	background-size: 18px 18px;
	display: inline-block;
	cursor: pointer;
}

// 選択時
input[type="checkbox"]:checked+label {
	background-image: url(../img/arrow/checkbox_on.svg);
	background-position: left 50%;
	background-size: 18px 18px;
}


// 送信ボタン
input[type="submit"],
input[type="reset"],
input[type="button"],
button[type="submit"],
button[type="button"] {

	&.input_submit {
		display: inline-block;
		width: auto;
		min-width: 300px;
		text-align: center;
		padding: 20px;
		// margin-left: 77px;
		@extend .font_size_24;
		font-weight: $demibold;
		border-radius: 5px;
		color: #FFF;
		background-color: $acc-color;

		border: none;
		cursor: pointer;

		background-image: url(../img/arrow/arrow_right_w.svg);
		background-repeat: no-repeat;
		background-size: 9px 16px;
		background-position: center right 55px;

		@include mq() {
			min-width: 205px;
			// margin-right: 57px;
			padding: 18px 20px 18px 0;
			background-position: center right 40px;
		}
	}

	&:hover {
		opacity: 0.8;
	}


	&.button_confirm {
		display: inline-block;
		width: auto;
		min-width: 300px;
		text-align: center;
		padding: 20px;
		@extend .font_size_24;
		font-weight: $demibold;
		border-radius: 5px;
		color: #FFF;
		background-color: $err-color;

		border: none;
	}


	&.input_return {
		display: inline-block;
		width: auto;
		border: none;
		padding: 0 0 0 15px;
		margin-right: 57px;
		min-width: inherit;
		color: $main-color;
		@extend .font_size_24;
		text-decoration: underline;
		background-color: transparent;
		transition: none;
		cursor: pointer;

		@include mq() {
			width: auto;
		}

	}


	p#fm_ajax_message {
		font-weight: bold;
		color: $main-color;
		letter-spacing: 1px;
		@extend .font_size_14;
	}
}