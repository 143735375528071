@charset "utf-8";
/*
 * EDITOR STYLE
 * WPのGutenbergにあてるスタイル
 * _wp.scssとeditor-style.scssからインポートされるmixin　editor()を定義
 * [ 注意1 ] _adjust.scssで定義したクラス以外は@extendできません。
 * [ 注意2 ] Webフォントは使用しないでください。
 */

// editor()内に投稿スタイルを記載していください
@mixin editor() {

    // 見出し
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $demibold;
    }

    h1 {
        padding: 2px 0 2px 8px;
        margin-bottom: 1em;
        line-height: 1.3em;
        color: $main-color;
        border-left: $main-color 3px solid;
        @extend .font_size_24;
    }

    h2 {
        padding: 2px 0 2px 0;
        margin-bottom: 49px;
        line-height: 1.3em;
        color: $base-font-color;
        @extend .font_size_26;

        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -17px;
            width: 85px;
            height: 2px;
            background-color: $acc-color;
        }
    }

    h3 {
        @extend .font_size_20;
        letter-spacing: 0.1em;
        background-color: $box;
        padding: 7px 0 6px 21px;
        margin-bottom: 30px;


    }

    h4 {
        @extend .font_size_18;
        border-bottom: 1px solid $acc-color;
        padding: 0 1em 2px 0;
        margin: 0 1em 20px;
    }

    h5 {
        @extend .font_size_18;
        color: $acc-color;
        padding-left: 1.5em;
        margin-bottom: 15px;
    }

    h6 {
        @extend .font_size_18;
        font-weight: $bold;
        padding-left: 2em;
        margin-bottom: 10px;
    }

    // リンク設定
    a {

        &:link,
        &:visited,
        &:active,
        &:hover {
            text-decoration: underline;
            color: $main-color;
        }

    }

    // テキスト
    p {
        margin-bottom: 1em;

        br {
            clear: inherit;
        }
    }

    br {
        clear: both;

        // clearfix
        @extend %clearfix;
    }

    // 中央揃え
    .center {
        text-align: center;
        text-indent: 0;
    }

    // 右揃え
    .left {
        float: left;
        text-indent: 0;
    }

    // 左揃え
    .right {
        float: right;
        text-indent: 0;
    }

    // 太字
    strong {
        font-weight: bold;
    }

    // イタリック
    em {
        font-style: italic;
    }

    // 引用
    blockquote {
        background-color: $box;
        padding: 3em 1em;
        position: relative;
        margin-bottom: 15px;
        border-radius: 10px;
        border: none;

        &:before {
            content: "“";
            font-size: 400%;
            line-height: 1em;
            color: #FFF;
            position: absolute;
            left: 10px;
            top: 10px;

            @include mq() {
                left: 5px;
                top: 5px;
            }
        }

        &:after {
            content: "”";
            font-size: 400%;
            line-height: 0em;
            color: #FFF;
            position: absolute;
            right: 10px;
            bottom: 10px;

            @include mq() {
                right: 5px;
                bottom: 5px;
            }
        }
    }

    // 画像
    img {
        max-width: 100%;
        height: auto !important;
        margin: 0 auto;
    }

    // float 右
    .f_left {
        float: left;

        @include mq(sp) {
            float: none;
        }
    }

    // float 左
    .f_right {
        float: right;

        @include mq(sp) {
            float: none;
        }
    }

    // 画像を中央
    .aligncenter {
        margin: 3% auto 0;
    }

    // 画像を左
    .alignright {
        float: right;
        margin: 3% 0 0 3%;

        @include mq(sp) {
            float: none;
            margin: 3% auto;
        }
    }

    // 画像を右
    .alignleft {
        float: left;
        margin: 3% 3% 0 0;

        @include mq(sp) {
            float: none;
            margin: 3% auto;
        }
    }

    // リスト
    ul,
    ol {
        margin: 3% 0;
        font-weight: $medium;

        @include mq() {
            padding: 10px 0 10px 10px;
        }
    }

    li {
        line-height: 1.5em;
        list-style-position: inside;

        @include mq(sp) {
            line-height: 1.7em;
        }
    }

    ul {
        list-style-type: none;

        li {
            background-image: url(../img/article/icon_list.svg);
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 6px;
            padding-left: 11px;
        }
    }

    ol {

        li {
            list-style-type: decimal;
        }
    }


    table {
        border-collapse: collapse;

        th,
        td {
            border: 1px solid $border-color;
            padding: 5px 10px;
        }

        th {
            font-weight: bold;
        }
    }


    //埋め込み系
    .wp-block-embed__wrapper {
        iframe {
            width: 100% !important;
        }
    }

    .wp-block-embed-youtube {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        margin-bottom: 60px !important;
        margin-top: 5%;

        .wp-block-embed__wrapper {
            position: static;
        }

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    //動画とかのキャプション
    .wp-block-embed figcaption {
        position: absolute;
        z-index: 10;
        top: 100.25%;
        left: 0;
        right: 0;
        font-size: 80%;
        // margin: 2% auto 8%;
        text-align: center;
    }

    .wp-block-image figcaption {
        font-size: 80%;
        text-align: center;
        margin-bottom: 20px;
    }


    //ボタン
    .wp-block-file__button {

        background-color: $main-color;
        color: #fff !important;
        text-decoration: none !important;

        &:hover {
            background-color: $sub-color;
            color: #fff !important;
        }
    }


    .wp-block-button {

        &.aligncenter {
            text-align: center;
        }

        a {
            margin: 0 auto 50px;
            width: 90%;
            max-width: 250px;
            color: #fff;
            background: $sub-color !important;
            padding: 15px 20px;
            border-radius: 100px;
            text-decoration: none;
            text-align: center;
            display: block;

            &:hover {
                opacity: 0.8;
            }
        }
    }


    //SNSプラグイン

    .a2a_button_twitter,
    .a2a_button_facebook,
    .a2a_button_line {
        span {
            background-color: transparent !important;
        }

        path {
            fill: $main-color;
        }
    }

    .wp-block-cover {
        margin: 3% auto;
    }

}